<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_subbranch')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <!--<strong> Branch Information</strong><br />
                    <v-text-field
                            v-model="formPostData.BranchInformation.BranchName"
                            label="Branch Name"
                    ></v-text-field>
                    <v-text-field
                            v-model="formPostData.BranchInformation.BranchAddress"
                            label="Branch Address"
                            :error-messages="BranchAddressErrors"
                            required
                    ></v-text-field>
                    <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="items"
                                                  item-value="id"
                                                  item-text="value"
                                                  label="Hotel Type"></v-select>
                    <v-text-field
                            v-model="formPostData.BranchInformation.BranchPhoneNumber"
                            label="Branch Contact Number"
                    ></v-text-field><br />

                    <strong> User Information</strong><br />
                    <v-text-field
                            v-model="formPostData.UserDetails.FirstName"
                            label="First Name"
                    ></v-text-field>
                    <v-text-field
                            v-model="formPostData.UserDetails.LastName"
                            label="Last Name"
                    ></v-text-field>
                    <v-text-field
                            v-model="formPostData.UserDetails.Address"
                            label="Address"
                    ></v-text-field>
                    <v-text-field
                            v-model="formPostData.UserDetails.Username"
                            label="Username"
                    ></v-text-field>
                    <v-text-field
                            v-model="formPostData.UserDetails.Password"
                            label="Password"
                            type="password"
                    ></v-text-field>
                     <v-text-field v-model="formPostData.UserDetails.confirmPassword"
                                   label="Confirm Pasword"
                                   type="password"
                                   :error-messages="confirmPasswordErrors"
                                   @input="$v.formPostData.UserDetails.confirmPassword.$touch()"
                                   @blur="$v.formPostData.UserDetails.confirmPassword.$touch()">
                         <template v-slot:label>
                             {{ $t("confirm_password") }}
                         </template>
                     </v-text-field>-->

                    <v-row>
                        <v-col outlined>
                            <v-card class="mx-auto pa-4" outlined>
                                <v-app-bar color="blue lighten-1" dark>
                                    <v-row justify="center" align="center">
                                        <p class="title">{{branchName}} {{ $t("information") }}</p>
                                    </v-row>
                                </v-app-bar>
                                <v-row>
                                    <v-col cols="12" md="6" class="my-1 py-1 mt-4">
                                        <v-select v-model="formPostData.UserDetails.provinceId"
                                                  :items="province"
                                                  item-value="id"
                                                  item-text="value"
                                                  dense
                                                  outlined
                                                  :error-messages="provinceIdErrors"
                                                  @input="$v.formPostData.UserDetails.provinceId.$touch()"
                                                  @blur="$v.formPostData.UserDetails.provinceId.$touch()">
                                            <template v-slot:label>
                                                {{ $t("select_province") }}
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1 mt-4">
                                        <v-autocomplete v-model="formPostData.UserDetails.divisionId"
                                                        :items="division"
                                                        item-value="id"
                                                        item-text="value"
                                                        dense
                                                  outlined
                                                        @change="DivisionChanged"
                                                        :error-messages="divisionIdErrors"
                                                        @input="$v.formPostData.UserDetails.divisionId.$touch()"
                                                        @blur="$v.formPostData.UserDetails.divisionId.$touch()">
                                            <template v-slot:label>
                                                {{ $t("select_business_sector") }}
                                            </template>
                                        </v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-select v-model="formPostData.BranchInformation.District"
                                                  :items="districts"
                                                  item-value="id"
                                                  item-text="value"
                                                  required
                                                  dense
                                                  outlined
                                                  :error-messages="BranchADistrictErrors"
                                                  @input="$v.formPostData.BranchInformation.District.$touch()"
                                                  @blur="$v.formPostData.BranchInformation.District.$touch()">
                                            <template v-slot:label>
                                                {{ $t("district") }}
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchName"
                                                      required
                                                      :error-messages="BranchNameErrors"
                                                      dense
                                                  outlined
                                                      @input="$v.formPostData.BranchInformation.BranchName.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchName.$touch()">
                                            <template v-slot:label>
                                                {{ $t("branch_name") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchNameNepali"
                                                      required
                                                      dense
                                                  outlined
                                                      :error-messages="BranchNameNepaliErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchNameNepali.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchNameNepali.$touch()">
                                            <template v-slot:label>
                                                {{ $t("branch_name_nepali") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchAddress"
                                                      required
                                                      dense
                                                  outlined
                                                      :error-messages="BranchAddressErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchAddress.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchAddress.$touch()">
                                            <template v-slot:label>
                                                {{ $t("branch_address") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchAddressNepali"
                                                      required
                                                      dense
                                                  outlined
                                                      :error-messages="BranchAddressNepaliErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchAddressNepali.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchAddressNepali.$touch()">
                                            <template v-slot:label>
                                                {{ $t("branch_address_nepali") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-select v-model="formPostData.BranchInformation.BranchType"
                                                  :items="items"
                                                  item-value="id"
                                                  item-text="value"
                                                  dense
                                                  outlined
                                                  required
                                                  :error-messages="BranchTypeErrors"
                                                  @input="$v.formPostData.BranchInformation.BranchType.$touch()"
                                                  @blur="$v.formPostData.BranchInformation.BranchType.$touch()">
                                            <template v-slot:label>
                                                {{ $t("_type") }}
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-menu v-model="dateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="formPostData.BranchInformation.EstablishedYear"
                                                              clearable
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"
                                                              required
                                                              dense
                                                  outlined
                                                              :error-messages="EstablishedYearErrors"
                                                              @input="$v.formPostData.BranchInformation.EstablishedYear.$touch()"
                                                              @blur="$v.formPostData.BranchInformation.EstablishedYear.$touch()">
                                                    <template v-slot:label>
                                                        {{ $t("establishment_year") }}
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="formPostData.BranchInformation.EstablishedYear">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6" class="my-1 py-1 ">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchLatitude"
                                                      dense
                                                  outlined>
                                            <template v-slot:label>
                                                {{ $t("latitude") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="my-1 py-1 ">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchLongitude"
                                                      dense
                                                  outlined>
                                            <template v-slot:label>
                                                {{ $t("longitude") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchEmail"
                                                      required
                                                      :error-messages="BranchEmailErrors"
                                                      dense
                                                  outlined
                                                      @input="$v.formPostData.BranchInformation.BranchEmail.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchEmail.$touch()">
                                            <template v-slot:label>
                                                {{ $t("email") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchWebsite"
                                                      dense
                                                  outlined>
                                            <template v-slot:label>
                                                {{ $t("website") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.BranchInformation.BranchPhoneNumber"
                                                      required
                                                      dense
                                                  outlined
                                                      :error-messages="BranchPhoneNumberErrors"
                                                      @input="$v.formPostData.BranchInformation.BranchPhoneNumber.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.BranchPhoneNumber.$touch()">
                                            <template v-slot:label>
                                                {{ $t("phone_number") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-file-input v-model="formPostData.BranchInformation.Logo"
                                                      show-size
                                                      dense
                                                  outlined
                                                      :error-messages="LogoErrors"
                                                      @input="$v.formPostData.BranchInformation.Logo.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.Logo.$touch()">
                                            <template v-slot:label>
                                                {{ $t("logo") }}
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-file-input v-model="formPostData.BranchInformation.RegisterDocument"
                                                      show-size
                                                      dense
                                                  outlined
                                                      :error-messages="RegisterDocumentErrors"
                                                      @input="$v.formPostData.BranchInformation.RegisterDocument.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.RegisterDocument.$touch()">
                                            <template v-slot:label>
                                                {{ $t("register_document") }}
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-file-input v-model="formPostData.BranchInformation.TaxClearanceDocument"
                                                      show-size
                                                      dense
                                                  outlined
                                                      :error-messages="TaxClearanceDocumentErrors"
                                                      @input="$v.formPostData.BranchInformation.TaxClearanceDocument.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.TaxClearanceDocument.$touch()">
                                            <template v-slot:label>
                                                {{ $t("tax_clearance_document") }}
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-file-input v-model="formPostData.BranchInformation.VatDocument"
                                                      show-size
                                                      dense
                                                  outlined
                                                      :error-messages="VatDocumentErrors"
                                                      @input="$v.formPostData.BranchInformation.VatDocument.$touch()"
                                                      @blur="$v.formPostData.BranchInformation.VatDocument.$touch()">
                                            <template v-slot:label>
                                                {{ $t("vat_document") }}
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>


                        <v-col>
                            <v-card class="mx-auto pa-4" outlined>
                                <v-app-bar color="blue lighten-1" dark>
                                    <v-row justify="center" align="center">
                                        <p class="title" justify="center">{{ $t("user_info") }}</p>
                                    </v-row>
                                </v-app-bar>

                                <v-row>
                                    <v-col cols="12" md="6" class="my-1 py-1 mt-4">
                                        <v-text-field v-model="formPostData.UserDetails.firstName"
                                                      required
                                                      dense
                                                      outlined
                                                      :error-messages="firstNameErrors"
                                                      @input="$v.formPostData.UserDetails.firstName.$touch()"
                                                      @blur="$v.formPostData.UserDetails.firstName.$touch()">
                                            <template v-slot:label>
                                                {{ $t("first_name") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1 mt-4">
                                        <v-text-field v-model="formPostData.UserDetails.lastName"
                                                      label="Last Name"
                                                      dense
                                                      outlined
                                                      :error-messages="lastNameErrors"
                                                      @input="$v.formPostData.UserDetails.lastName.$touch()"
                                                      @blur="$v.formPostData.UserDetails.lastName.$touch()">
                                            <template v-slot:label>
                                                {{ $t("last_name") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.address"
                                                      label="Address"
                                                      dense
                                                      outlined
                                                      :error-messages="addressErrors"
                                                      @input="$v.formPostData.UserDetails.address.$touch()"
                                                      @blur="$v.formPostData.UserDetails.address.$touch()">
                                            <template v-slot:label>
                                                {{ $t("address") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.email"
                                                      label="E-mail"
                                                      dense
                                                      outlined
                                                      :error-messages="emailErrors"
                                                      @input="$v.formPostData.UserDetails.email.$touch()"
                                                      @blur="$v.formPostData.UserDetails.email.$touch()">
                                            <template v-slot:label>
                                                {{ $t("email") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.userName"
                                                      label="User Name"
                                                      dense
                                                      outlined
                                                      :error-messages="userNameErrors"
                                                      @input="$v.formPostData.UserDetails.userName.$touch()"
                                                      @blur="$v.formPostData.UserDetails.userName.$touch()">
                                            <template v-slot:label>
                                                {{ $t("user_name") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.password"
                                                      label="Password"
                                                      required
                                                      type="password"
                                                      dense
                                                      outlined
                                                      :error-messages="passwordErrors"
                                                      @input="$v.formPostData.UserDetails.password.$touch()"
                                                      @blur="$v.formPostData.UserDetails.password.$touch()">
                                            <template v-slot:label>
                                                {{ $t("password") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="my-1 py-1">
                                        <v-text-field v-model="formPostData.UserDetails.confirmPassword"
                                                      label="Confirm Pasword"
                                                      type="password"
                                                      dense
                                                      outlined
                                                      :error-messages="confirmPasswordErrors"
                                                      @input="$v.formPostData.UserDetails.confirmPassword.$touch()"
                                                      @blur="$v.formPostData.UserDetails.confirmPassword.$touch()">
                                            <template v-slot:label>
                                                {{ $t("confirm_password") }}
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                </v-row>
                            </v-card>
                            <v-card-actions class="pt-12">
                                <v-spacer></v-spacer>
                                <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
                                <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </form>

            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
    import { email, required, sameAs } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "CreatePackageForm",
        props: ["success"],

        computed: {
            provinceIdErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.provinceId.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.provinceId.required &&
                        errors.push(`${this.$t("province_is_required")}`);
                }
                return errors;
            },
            divisionIdErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.divisionId.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.divisionId.required &&
                        errors.push(`${this.$t("business_sector_is_required")}`);
                }
                return errors;
            },
            firstNameErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.firstName.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.firstName.required &&
                        errors.push(`${this.$t("first_name_is_required")}`);
                }
                return errors;
            },
            userNameErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.userName.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.userName.required &&
                        errors.push(`${this.$t("user_name_is_required")}`);
                }
                return errors;
            },
            lastNameErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.lastName.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.lastName.required &&
                        errors.push(`${this.$t("last_name_is_required")}`);
                }
                return errors;
            },
            addressErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.address.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.address.required &&
                        errors.push(`${this.$t("address_is_required")}`);
                }
                return errors;
            },
            emailErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.email.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.email.email &&
                        errors.push(`${this.$t("must_be_valid_email")}`);
                    !this.$v.formPostData.UserDetails.email.required &&
                        errors.push(`${this.$t("email_is_required")}`);
                }
                return errors;
            },
            passwordErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.password.$dirty) return errors;
                    !this.$v.formPostData.UserDetails.password.required &&
                        errors.push(`${this.$t("password_is_required")}`);
                }
                return errors;
            },
            confirmPasswordErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.UserDetails.confirmPassword.$dirty)
                        return errors;
                    !this.$v.formPostData.UserDetails.confirmPassword.required &&
                        errors.push(`${this.$t("confirm_password_is_required")}`);
                    !this.$v.formPostData.UserDetails.confirmPassword.sameAsPassword &&
                        errors.push(`${this.$t("password_must_be_identical")}`);
                }
                return errors;
            },
            BranchPhoneNumberErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchPhoneNumber.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchPhoneNumber.required &&
                        errors.push(`${this.$t("phone_number_is_required")}`);
                }
                return errors;
            },
            BranchAddressErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchAddress.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchAddress.required &&
                        errors.push(`${this.$t("address_is_required")}`);
                }
                return errors;
            },
            BranchAddressNepaliErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchAddressNepali.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchAddressNepali.required &&
                        errors.push(`${this.$t("address_is_required")}`);
                }
                return errors;
            },
            BranchADistrictErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.District.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.District.required &&
                        errors.push(`${this.$t("district_is_required")}`);
                }
                return errors;
            },
            EstablishedYearErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.EstablishedYear.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.EstablishedYear.required &&
                        errors.push(`${this.$t("establishment_year_is_required")}`);
                }
                return errors;
            },
            BranchEmailErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchEmail.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchEmail.email &&
                        errors.push(`${this.$t("must_be_valid_email")}`);
                    !this.$v.formPostData.BranchInformation.BranchEmail.required &&
                        errors.push(`${this.$t("email_is_required")}`);
                }
                return errors;
            },
            BranchNameErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchName.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchName.required &&
                        errors.push(`${this.$t("name_is_required")}`);
                }
                return errors;
            },
            BranchNameNepaliErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.BranchNameNepali.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.BranchNameNepali.required &&
                        errors.push(`${this.$t("name_is_required")}`);
                }
                return errors;
            },
            LogoErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.Logo.$dirty) return errors;
                    !this.$v.formPostData.BranchInformation.Logo.required &&
                        errors.push(`${this.$t("document_is_required")}`);
                }
                return errors;
            },
            RegisterDocumentErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.RegisterDocument.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.RegisterDocument.required &&
                        errors.push(`${this.$t("document_is_required")}`);
                }
                return errors;
            },
            TaxClearanceDocumentErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.TaxClearanceDocument.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.TaxClearanceDocument.required &&
                        errors.push(`${this.$t("document_is_required")}`);
                }
                return errors;
            },
            VatDocumentErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formPostData.BranchInformation.VatDocument.$dirty)
                        return errors;
                    !this.$v.formPostData.BranchInformation.VatDocument.required &&
                        errors.push(`${this.$t("document_is_required")}`);
                }
                return errors;
            }


        },
        watch: {
            close: {
                handler: function (val) {
                    let data = {};
                    data.val = val;
                    data.type = "close";
                    this.$emit("formResponse", val, this.responseData);
                    this.close = false;
                },
                deep: true
            },
            "formPostData.UserDetails.provinceId": {
                handler: async function (val, oldVal) {
                    if (val != oldVal) {
                        this.error = true;
                        this.division = [];
                        this.branch = [];
                        let disNepali = localStorage.getItem('lang');
                        if (disNepali == 'en') {
                            const divisionBranch = await axios.get("Division/DDLDivisionList/", {
                                params: { ProvinceID: val }

                            });
                            this.division = divisionBranch.data
                        }
                        if (disNepali == 'ne') {
                            const divisionBranch = await axios.get("Division/DDLDivisionListNE/", {
                                params: { ProvinceID: val }
                            });
                            this.division = divisionBranch.data
                        }

                        let disNep = localStorage.getItem('lang');
                        if (disNep == 'en') {
                            const { data } = await axios.get("District/GetDistrictListByProvince/" + val);
                            this.districts = data
                        }
                        if (disNep == 'ne') {
                            const { data } = await axios.get("District/GetDistrictListByProvinceNE/" + val);
                            this.districts = data
                        }
                        this.formPostData.UserDetails.provinceId = val;
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                responseData: {},
                returnData: [],
                callbackResponse: {
                    timeout: 6000
                },
                province: [],
                errorMessage: "",
                snackbar: false,
                close: false,
                error: false,
                created: false,
                dateMenu: false,
                districts: [],
                show: false,
                items: [
                    { id: 'Domestic', value: 'Domestic' },
                    { id: 'International', value: 'International' },
                ],
                formPostData: {
                    BranchInformation: {},
                    UserDetails: {}
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            formPostData: {
                UserDetails: {
                    provinceId: { required },
                    divisionId: { required },
                    firstName: { required },
                    lastName: { required },
                    address: { required },
                    email: { required, email },
                    password: { required },
                    userName: { required },
                    confirmPassword: {
                        required,
                        sameAsPassword: sameAs("password")
                    }
                },
                BranchInformation: {
                    BranchPhoneNumber: { required },
                    District: { required },
                    Logo: { required },
                    BranchName: { required },
                    BranchNameNepali: { required },
                    RegisterDocument: { required },
                    TaxClearanceDocument: { required },
                    VatDocument: { required },
                    BranchAddress: { required },
                    BranchAddressNepali: { required },
                    EstablishedYear: { required },
                    BranchEmail: { required, email }
                },
            },
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                let provNepali = localStorage.getItem('lang');
                if (provNepali === 'en') {
                    const provinceRequest = await axios.get("Province/DDLProvinceList");
                    this.province = provinceRequest.data;
                }
                else {
                    const provinceRequest = await axios.get("Province/DDLProvinceListNE");
                    this.province = provinceRequest.data;
                }
                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    this.formPostData.isAnonymous = true;
                    var form = new FormData();

                    form.append(
                        "BranchInformation.Logo",
                        this.formPostData.BranchInformation.Logo
                    );
                    form.append(
                        "BranchInformation.TaxClearanceDocument",
                        this.formPostData.BranchInformation.TaxClearanceDocument
                    );
                    form.append(
                        "BranchInformation.BranchType",
                        this.formPostData.BranchInformation.BranchType
                    );
                    form.append(
                        "BranchInformation.VatDocument",
                        this.formPostData.BranchInformation.VatDocument
                    );
                    form.append(
                        "BranchInformation.RegisterDocument",
                        this.formPostData.BranchInformation.RegisterDocument
                    );
                    form.append(
                        "BranchInformation.BranchName",
                        this.formPostData.BranchInformation.BranchName
                    );
                    form.append(
                        "BranchInformation.BranchNameNepali",
                        this.formPostData.BranchInformation.BranchNameNepali
                    );
                    form.append(
                        "BranchInformation.BranchAddress",
                        this.formPostData.BranchInformation.BranchAddress
                    );
                    form.append(
                        "BranchInformation.BranchAddressNepali",
                        this.formPostData.BranchInformation.BranchAddressNepali
                    );
                    form.append(
                        "BranchInformation.District",
                        this.formPostData.BranchInformation.District
                    );
                    form.append(
                        "BranchInformation.EstablishedYear",
                        this.formPostData.BranchInformation.EstablishedYear
                    );
                    form.append(
                        "BranchInformation.BranchPhoneNumber",
                        this.formPostData.BranchInformation.BranchPhoneNumber
                    );
                    form.append(
                        "BranchInformation.BranchWebsite",
                        this.formPostData.BranchInformation.BranchWebsite
                    );
                    form.append(
                        "BranchInformation.Latitude",
                        this.formPostData.BranchInformation.BranchLatitude
                    );
                    form.append(
                        "BranchInformation.Longitude",
                        this.formPostData.BranchInformation.BranchLongitude
                    );
                    form.append(
                        "BranchInformation.BranchEmail",
                        this.formPostData.BranchInformation.BranchEmail
                    );
                    form.append(
                        "BranchInformation.BranchCode",
                        this.formPostData.BranchInformation.BranchCode
                    );
                    form.append(
                        "UserDetails.FirstName",
                        this.formPostData.UserDetails.firstName
                    );
                    form.append("UserDetails.MinistryID", 1);
                    form.append(
                        "UserDetails.LastName",
                        this.formPostData.UserDetails.lastName
                    );
                    form.append(
                        "UserDetails.Address",
                        this.formPostData.UserDetails.address
                    );
                    form.append("UserDetails.Email", this.formPostData.UserDetails.email);
                    form.append(
                        "UserDetails.UserName",
                        this.formPostData.UserDetails.userName
                    );
                    form.append(
                        "UserDetails.Password",
                        this.formPostData.UserDetails.password
                    );
                    form.append(
                        "UserDetails.DivisionID",
                        this.formPostData.UserDetails.divisionId
                    );
                    form.append(
                        "UserDetails.ProvinceID",
                        this.formPostData.UserDetails.provinceId
                    );
                    form.append("isAnonymous", "true");
                    this.snackbar = false;
                    axios
                        .post("User/CreateAirportManagerUser", form)
                        .then(response => {
                            this.responseData = response.data;
                            if (response.data.success) {
                                this.editedData = {};
                                this.servicesData.data = response.data
                                this.servicesData.message = "User Register Succcessfully Wait For Confirmation"
                                this.servicesData.color = "success"
                                this.dialogueClose()
                            } else {
                                console.log(response.data.errors[0]);
                                this.errorMessage = response.data.errors[0];
                                this.snackbar = true;
                            }
                        })
                        .catch(data => {
                            console.log(data);
                            this.errorMessage = "Server Error, Please Contact Admin";
                            this.snackbar = true;
                        });
                }
                this.submitStatus = "PENDING";
                setTimeout(() => {
                    this.submitStatus = "OK";
                }, 500);
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #1565c0;
    }

    input {
        display: none;
    }

    .v-card--outlined {
        height: 100%;
    }
</style>