<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>

        <v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-dialog v-model="dialogEdit" max-width="500px">
            <editForm v-if="dialogEdit"
                      :editData="editedDataPackage"
                      :success="success"
                      @formResponse="onResponse" />
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <v-dialog v-model="dialogCreate" max-width="1000px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="ma-4 float-right" v-on="on" :success="success">{{$t('add_subbranch')}}</v-btn>
            </template>
            <createForm :success="success" @formResponse="onResponse" />
        </v-dialog>
        <v-container fluid>
            <!--<v-expansion-panels hover>
              <v-expansion-panel>
                <v-expansion-panel-header>Filter</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <v-row col="12">

                        </v-row>
                    </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>-->

            <vue-good-table mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true,  perPageDropdown: [20, 50, 100,200]}"
                            :rows="roomRows"
                            :columns="columnsRoom">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                        <v-chip class="ma-2"
                                color="green"
                                text-color="white"
                                @click="editItem(props.row)">{{$t('table.details')}}</v-chip>
                    </span>
                </template>



                <!--<template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
                <router-link v-for="room in roomRows" :key="room" :to="{name: 'Details', params:{hotelID: room.branchID}}">
                    <v-chip class="ma-2"
                            color="green"
                            text-color="white">Details</v-chip>
                </router-link>
            </span>
        </template>-->
            </vue-good-table>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import createForm from "./CreateSubBranch";
    import editForm from "./EditSubBranch";
    import deleteListData from "@/components/deleteModal";
    import { parseJwt } from "@/mixins/parseJwt";

    export default {
        name: "package_create_form",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        components: {
            createForm,
            editForm,
            deleteListData
        },
        computed: {
            breadCrumb() {
                return [
                    {
                        text: this.$t('dashboard'),
                        to: "/AirportDashboard",
                        disabled: false,
                        exact: true
                    },
                    {
                        text: this.$t('airport_manager'),
                        disabled: true
                    }
                    ]
            }
            },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                columnsRoom: [
                    {
                        label: 's_no',
                        field: "countIndex"
                    },
                    {
                        label: 'form.branch_name',
                        field: "branchName"
                    },
                    {
                        label: 'branch_address',
                        field: "branchAddress"
                    },
                    {
                        label: 'phone_number',
                        field: "branchPhoneNumber"
                    },
                    {
                        label: 'actions',
                        field: "actions",
                        sortable: false
                    }
                ],
                roomRows: [],
                totalRecords: 0,
                serverParamsRoom: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "BranchName",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 20
                },
                user: [],
                editedIndex: -1,
                editedDataRoom: {}
            };
        },

        mounted() {
            this.loadItems();
            this.filterData();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            editItem(props) {
                console.log('caa', props)
                window.location.href = 'AirportDetailDashboard/'+props.branchID;
            },

            onResponse(data) {
                console.log("ok")
                if (data) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            onResponseDelete(data) {
                if (data) {
                    this.$emit("formResponseRoomDelete", data);
                    this.dialogDelete = false;
                    this.loadItems();
                } else {
                    this.dialogDelete = false;
                }
            },

            deleteItemRoom(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url = "Airport/DeleteHotelPackageRoomAsync/" + props.hotelPackageID;
                this.loadItems();
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            updateParams(newProps) {
                this.serverParamsRoom = Object.assign({}, this.serverParamsRoom, newProps);
            },

            searchInput() {
                this.loadItems();
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            async filterData() {
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParamsRoom.perPage,
                    PageNo: this.serverParamsRoom.page,
                    OrderType: this.serverParamsRoom.sort[0].type,
                    OrderBy: this.serverParamsRoom.sort[0].field
                };
                try {
                    axios.post("Airport/GetAirportListAsync", param).then(response => {
                        this.roomRows = response.data.data;
                        this.totalRecords = response.data.totalCount
                    });


                    // this.totalRecords = response.data.totalCount;
                } catch (e) {
                    if (e.response.data.State == "AccessTokenExpired") {
                        this.apiResponse(e);
                        this.loadItems();
                    }
                }
                return;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>